import { type FC } from 'react'

import { Backdrop, Stack, Typography } from '@mui/material'
import { useLayout } from '@core/contexts/layout'

import Spinner from '../Spinner'

interface Props {
  title: string
  loading: boolean
  // setOpen: (v: boolean) => void
}

export const BackdropLoader: FC<Props> = ({ title, loading }) => {
  // const handleClose = () => {
  //   setOpen(false)
  // }
  const { isMobile } = useLayout()

  return (
    <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 999 }} open={loading}>
      <Stack direction='column' justifyContent='center' gap={isMobile ? '1rem' : '2.6rem'}>
        <Spinner size={isMobile ? 70 : 88} position='relative' />
        <Typography
          textAlign='center'
          sx={{ bottom: -50, left: 0 }}
          width={500}
          variant='h4'
          fontWeight={500}
        >
          {title}
        </Typography>
      </Stack>
    </Backdrop>
  )
}
