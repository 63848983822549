import Image from 'next/image'

import { Box, Stack } from '@mui/material'
import { CalendarOutlinedIcon } from '@core/components/icons'
import { CardIconSmall } from '@core/components/ui/Cards/CardIconSmall'
import { useLayout } from '@core/contexts/layout'
import { colors } from '@core/helpers'

const IMAGE_PATH = '/images/login/banner.png'

export const BannerLoginLayout = () => {
  const { isTabletLarge, isDesktop } = useLayout()

  return (
    <Stack height='38.7rem' mb='6.3rem' position='relative' justifyContent='flex-end'>
      <Box
        sx={{
          background: colors.secondary_blue,
          borderTopRightRadius: '15rem',
          width: '100%',
          height: '27.1rem'
        }}
      />
      <Image
        style={{
          position: 'absolute',
          right: '2.8rem',
          zIndex: 50,
          objectFit: 'cover'
        }}
        quality={100}
        loading='eager'
        src={IMAGE_PATH}
        width={348}
        priority
        height={387}
        alt='Una chica sosteniendo una tarjeta de crédito y sonriendo'
      />
      {(isTabletLarge || isDesktop) && (
        <>
          <CardIconSmall
            sxContainer={{
              position: 'absolute',
              zIndex: 100,
              right: '30rem',
              bottom: '3.5rem'
            }}
            label='<span style="color: var(--color-primary);" >Ver resultado</span> de exámenes'
            icon={size => <CalendarOutlinedIcon size={size} />}
          />
          <CardIconSmall
            sxContainer={{
              position: 'absolute',
              zIndex: 100,
              right: '-1rem',
              top: '7rem'
            }}
            label='<span style="color: var(--color-secondary-orange);" >Ver programas</span> de salud'
            icon={size => (
              <CalendarOutlinedIcon color='var(--color-secondary-orange)' size={size} />
            )}
          />
        </>
      )}
    </Stack>
  )
}
