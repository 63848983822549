import { z } from 'zod'

export interface LoginFormData {
  documentType: number | null
  documentNumber: string
  password: string
}

export const initialLoginForm: LoginFormData = {
  documentType: 1,
  documentNumber: '',
  password: ''
}

const ERRORS = {
  documentType: {
    required_error: 'Este campo es requerido',
    invalid_type_error: 'Ingresa una opción válida'
  },
  password: {
    required_error: 'Este campo es requerido',
    invalid_type_error: 'Tipo inválido'
  },
  documentNumber: {
    required_error: 'Este campo es requerido',
    invalid_type_error: 'Tipo inválido'
  }
} as const

export const loginSchema = z
  .object({
    documentType: z
      .number(ERRORS.documentType)
      .refine(value => value, ERRORS.documentType.required_error),
    documentNumber: z.string(ERRORS.documentNumber).nonempty(ERRORS.documentNumber.required_error),
    password: z.string(ERRORS.password).nonempty(ERRORS.password.required_error)
  })
  .superRefine(({ documentType, documentNumber }, ctx) => {
    if (documentType === 1) {
      if (documentNumber.length !== 8) {
        ctx.addIssue({
          path: ['documentNumber'],
          code: z.ZodIssueCode.custom,
          message: 'Mínimo 8 dígitos'
        })
      }

      return z.NEVER
    }
    if (documentNumber.length < 6) {
      ctx.addIssue({
        path: ['documentNumber'],
        code: z.ZodIssueCode.custom,
        message: 'Mínimo 6 dígitos'
      })
    }

    if (documentNumber.length > 12) {
      ctx.addIssue({
        path: ['documentNumber'],
        code: z.ZodIssueCode.custom,
        message: 'Máximo 12 dígitos'
      })
    }
  })
