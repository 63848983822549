export const generateUUID = (): string => {
  const isClient = typeof window !== 'undefined'
  const hasCrypto = isClient && typeof window?.crypto !== 'undefined'

  const crypto = hasCrypto ? window.crypto : undefined // Para compatibilidad con navegadores
  if (hasCrypto && typeof crypto?.randomUUID === 'function') return crypto.randomUUID()
  // Si el navegador no admite crypto, usa una versión menos segura basada en Math.random()
  const randomBytes = []
  for (let i = 0; i < 16; i++) {
    randomBytes.push(
      Math.floor(Math.random() * 256)
        .toString(16)
        .padStart(2, '0')
    )
  }

  return randomBytes.join('')
}
