/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect } from 'react'
import { type UseFormClearErrors, type UseFormSetValue, type UseFormWatch } from 'react-hook-form'

import { NUMERIC_REGEX } from '@core/lib/utils'
import useCatalogStore from '@core/stores/catalog/useCatalogStore'

interface Params {
  watch: UseFormWatch<any>
  setValue: UseFormSetValue<any>
  clearErrors: UseFormClearErrors<any>
  documentType: number | null
}

export const useDocumentInputs = ({ watch, setValue, clearErrors, documentType }: Params) => {
  const { documentTypesCatalog: documentTypes } = useCatalogStore()

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (name === 'documentType' && type === 'change') {
        setValue('documentNumber', '')
        clearErrors()
      }
    })

    return () => subscription.unsubscribe()
  }, [])

  useEffect(() => {
    if (!documentTypes) return
    setValue('documentType', documentTypes[0]?.id, { shouldValidate: true })
  }, [documentTypes])

  const onChangeDocumentNumber = (value: string) => {
    if (value.length > 0 && !NUMERIC_REGEX.test(value)) return
    if (documentType === 1) {
      if (value.length > 8) return
    } else if (value.length > 12) return

    setValue('documentNumber', value, { shouldValidate: true })
  }

  return {
    onChangeDocumentNumber,
    documentTypes
  }
}
