import { type GetServerSideProps } from 'next'
import { useRouter } from 'next/router'

import { useAuth } from '@core/contexts/auth/context'
import { createServerSideProps } from '@core/lib/data-fetching'
import { getCatalog } from '@services/catalog'
import { Login } from '@components/Login'

const LoginPage = () => {
  const { setAuthTokenToCookie } = useAuth()
  const router = useRouter()

  if (typeof window !== 'undefined') {
    // Tu código que depende de window va aquí
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const token = urlParams.get('token')
    const recetas = urlParams.get('recetas')
    const familiares = urlParams.get('familiares')
    const urlrecetas = 'https://miaviva.aviva.pe//mis-citas?section=historial'
    const urlfamily = 'https://miaviva.aviva.pe//mis-citas'

    //   setAuthTokenToCookie(token)
    if (token && recetas) {
      setAuthTokenToCookie(token)
      router.replace(urlrecetas)
    } else if (token && familiares) {
      router.replace(urlfamily)
      setAuthTokenToCookie(token)
      // router.replace({
      //   query: { app: true }
      // })
    }
  }

  return <Login />
}

export const getServerSideProps: GetServerSideProps = createServerSideProps(async ({ props }) => {
  const documentTypesCatalog = await getCatalog('documentTypes')

  return {
    props: {
      ...props,
      catalog: {
        documentTypesCatalog: documentTypesCatalog ?? []
      }
    }
  }
})

export default LoginPage
