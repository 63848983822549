import { LoginForm } from './LoginForm'
import { LoginLayout } from './LoginLayout'

export const Login = () => {
  return (
    <LoginLayout title='Iniciar sesión - Portal aviva'>
      <LoginForm />
    </LoginLayout>
  )
}
