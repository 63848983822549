import cn from 'clsx'

import s from './Spinner.module.scss'

type Props = {
  className?: string
  title?: string
  position?: 'absolute' | 'relative'
  size?: number
}

export default function Spinner({ className, title, position = 'absolute', size = 72 }: Props) {
  return (
    <div className={cn(s.root, s[`is-${position}`], className)}>
      <div className={s.content}>
        <i className='mb-16'>
          <svg
            className={s.spinner}
            width={size}
            height={size}
            viewBox='0 0 78 78'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle opacity='0.3' cx='39' cy='39' r='36' stroke='white' strokeWidth='4.90909' />
            <path
              d='M75 39C75 46.6605 72.5564 54.1213 68.0245 60.2974C63.4925 66.4736 57.1086 71.0431 49.8011 73.3415C42.4935 75.6399 34.6433 75.5473 27.3919 73.0772C20.1406 70.6071 13.8662 65.8882 9.48126 59.6069C5.09629 53.3256 2.82932 45.8092 3.01002 38.1508C3.19071 30.4925 5.80964 23.0914 10.486 17.0239C15.1623 10.9563 21.6522 6.53867 29.012 4.4133C36.3717 2.28794 44.2176 2.56568 51.4087 5.20613'
              stroke='url(#paint0_linear_540_9120)'
              strokeWidth='4.90909'
              strokeLinecap='round'
            />
            <path
              d='M25.913 44.4457H33.7933V33.9391H20.7217V39.1828C20.7217 39.874 20.856 40.5583 21.1168 41.1969C21.3777 41.8354 21.7601 42.4156 22.2422 42.9043C22.7242 43.393 23.2965 43.7806 23.9263 44.0451C24.5562 44.3096 25.2313 44.4457 25.913 44.4457Z'
              fill='#94DAFA'
            />
            <path
              d='M44.1576 52.428V44.441H33.7939V57.6909H38.9642C40.341 57.6909 41.6615 57.1364 42.635 56.1494C43.6086 55.1624 44.1555 53.8238 44.1555 52.428'
              fill='#94DAFA'
            />
            <path
              d='M52.0345 33.9391H44.1562V44.4457H57.2258V39.202C57.2258 37.8062 56.6788 36.4675 55.7053 35.4806C54.7317 34.4936 53.4113 33.9391 52.0345 33.9391Z'
              fill='#94DAFA'
            />
            <path
              d='M33.7939 25.9537V33.9428H44.1576V20.6844L38.9852 20.6844C38.303 20.6844 37.6274 20.8208 36.9972 21.0857C36.3669 21.3506 35.7943 21.7388 35.3122 22.2282C34.83 22.7176 34.4478 23.2985 34.1873 23.9378C33.9268 24.577 33.7931 25.2621 33.7939 25.9537Z'
              fill='#94DAFA'
            />
            <defs>
              <linearGradient
                id='paint0_linear_540_9120'
                x1='15.5'
                y1='14.5'
                x2='75'
                y2='35.5'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#5DBBE5' />
                <stop offset='1' stopColor='#5DBBE5' stopOpacity='0' />
              </linearGradient>
            </defs>
          </svg>
        </i>
        <p>{title}</p>
      </div>
    </div>
  )
}
