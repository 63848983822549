import { type FC } from 'react'
import NextLink from 'next/link'

import { Button, Stack, type SxProps, Typography } from '@mui/material'
import { useLayout } from '@core/contexts/layout'
import { colors } from '@core/helpers'

interface Props {
  href: string
  textButton: string
  textLabel: string
  sxContainer?: SxProps
}

export const LoginTopInfo: FC<Props> = ({ href, textButton, textLabel, sxContainer }) => {
  const { isMobile, isTablet } = useLayout()

  return (
    <Stack
      width='100%'
      justifyContent={isMobile ? 'space-between' : 'flex-end'}
      alignItems='center'
      direction='row'
      gap={isMobile ? '0' : isTablet ? '2rem' : '2.2rem'}
      position='relative'
      sx={{
        mb: isMobile ? '4rem' : '13.2rem',
        ...sxContainer
      }}
    >
      <Typography color={colors.text_body_b1} variant='body2'>
        {textLabel}
      </Typography>
      <Button
        LinkComponent={NextLink}
        href={href}
        sx={{ width: isMobile ? '14.8rem' : '18.4rem' }}
        variant='outlined'
        size='small'
      >
        {textButton}
      </Button>
    </Stack>
  )
}
