/* eslint-disable @typescript-eslint/indent */
import { type FC, type PropsWithChildren, useMemo } from 'react'
import Head from 'next/head'
import NextLink from 'next/link'

import parser from 'html-react-parser'

import { Stack, type StackProps, Typography } from '@mui/material'
import { AvivaLogo } from '@core/components/icons'
import { useLayout } from '@core/contexts/layout'
import { colors, colorToHTMLString } from '@core/helpers'
import { AVIVA_WEB_URL } from '@core/lib/utils'

import { BannerLoginLayout } from './BannerLoginLayout'

type Props = StackProps & {
  title: string
  description?: string
  leftBannerTitle?: string
  maxWidthDesktop?: number | string
  hideLeftSide?: boolean
}

const leftTitleDefault = `${colorToHTMLString(
  'Ten el control',
  colors.primary_lighter,
  700
)} de tus citas y tus programas de salud desde ${colorToHTMLString(
  'Mi aviva',
  colors.primary_lighter,
  700
)}`

export const LoginLayout: FC<PropsWithChildren<Props>> = ({
  children,
  maxWidthDesktop,
  title,
  hideLeftSide = false,
  leftBannerTitle = leftTitleDefault,
  description = 'Iniciar sesión en el portal de la clínica aviva',
  ...childrenProps
}) => {
  const { isMobile, isTablet, isDesktop, isDesktopSmall } = useLayout()

  const showLeftSide = useMemo(() => {
    if (isMobile) return false
    if (hideLeftSide) return false

    return true
  }, [isMobile, hideLeftSide])

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='description' content={description} />
      </Head>
      {isMobile && (
        <Stack
          sx={{ background: colors.white }}
          position='sticky'
          top={0}
          zIndex={50}
          component='header'
          alignItems='center'
        >
          <Stack
            maxWidth='90%'
            width='100%'
            top={0}
            direction='row'
            alignItems='center'
            height='6rem'
            component='nav'
          >
            <NextLink href={AVIVA_WEB_URL}>
              <AvivaLogo width={88} height={33} />
            </NextLink>
          </Stack>
        </Stack>
      )}
      <Stack component='main' direction='row' position='relative'>
        {showLeftSide && (
          <Stack
            height='100vh'
            position='sticky'
            overflow='auto'
            top={0}
            p={
              isTablet
                ? '4rem 3.5rem 4.4rem 0'
                : isDesktopSmall
                ? '4rem 6rem 4.4rem 0'
                : isDesktop
                ? '4rem 10.4rem 4.4rem 0'
                : undefined
            }
            flex={1}
            direction='column'
            alignContent='flex-end'
            justifyContent='space-between'
            sx={{ background: colors.primary }}
          >
            <Stack mb='5.1rem' pl='6rem' width='100%'>
              <Stack alignSelf='flex-end' alignItems='flex-start' width='100%' maxWidth='49.6rem'>
                <NextLink href={AVIVA_WEB_URL}>
                  <AvivaLogo white width={120} height={45} />
                </NextLink>
              </Stack>
            </Stack>
            <Stack width='100%'>
              <BannerLoginLayout />
              <Stack alignSelf='flex-end' mb='6.8rem' pl='6rem' width='100%'>
                <Stack alignSelf='flex-end' alignItems='flex-start' width='100%' maxWidth='49.6rem'>
                  <Typography variant='h3' textAlign='center' color={colors.white}>
                    {parser(leftBannerTitle)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
        <Stack
          flex={1}
          alignItems={isMobile ? 'center' : 'flex-start'}
          direction='column'
          mr={isTablet ? '3rem' : undefined}
          p={
            hideLeftSide
              ? isMobile
                ? undefined
                : '3.6rem'
              : isTablet
              ? '4rem 0 4.4rem 3.5rem'
              : isDesktopSmall
              ? '4rem 0 4.4rem 6rem'
              : isDesktop
              ? `4rem 0 4.4rem ${maxWidthDesktop ? '0' : '10.4rem'}`
              : undefined
          }
        >
          <Stack
            {...childrenProps}
            component='section'
            maxWidth={
              isMobile ? '90%' : maxWidthDesktop !== undefined ? maxWidthDesktop : '49.6rem'
            }
          >
            {children}
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
