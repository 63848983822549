/* eslint-disable unused-imports/no-unused-vars */
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import { Button, Link, Stack, Typography } from '@mui/material'
import { CustomInput, RHFCustomInput, RHFCustomSelect } from '@core/components/ui'
import { BackdropLoader } from '@core/components/ui/BackdropLoader/BackdropLoader'
import { useAuth } from '@core/contexts/auth/context'
import { useLayout } from '@core/contexts/layout'
import { colors } from '@core/helpers'
import { SERVER_ERROR_MESSAGE } from '@core/lib/utils'
import useMiniAlertStore from '@core/stores/miniAlert/useMiniAlertStore'
import { zodResolver } from '@hookform/resolvers/zod'
import { useDocumentInputs } from '@hooks/useDocumentInputs'
import { sendEventGA } from '@services/analytics'
import { loginUser } from '@services/login'

import { LoginTopInfo } from '../LoginTopInfo'
import { initialLoginForm, type LoginFormData, loginSchema } from './loginSchema'

export const LoginForm = () => {
  const { isMobile } = useLayout()
  const { setAuthTokenToCookie } = useAuth()
  const { openAlert } = useMiniAlertStore()

  const router = useRouter()
  const [isLoadingLogin, setIsLoadingLogin] = useState(false)

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    reset,
    clearErrors,
    watch,
    formState: { isSubmitting, errors }
  } = useForm<LoginFormData>({
    defaultValues: initialLoginForm,
    mode: 'onSubmit',
    resolver: zodResolver(loginSchema)
  })

  const values = watch()

  const { onChangeDocumentNumber, documentTypes } = useDocumentInputs({
    watch,
    setValue,
    clearErrors,
    documentType: values.documentType
  })

  const onSubmit = async ({ documentType, documentNumber, password }: LoginFormData) => {
    setIsLoadingLogin(true)
    if (!documentType) return

    try {
      const { token } = await loginUser({
        documentType,
        password,
        userName: documentNumber
      })

      sendEventGA('login', {
        method: 'normal',
        hash_doc: documentNumber,
        type_doc: documentTypes?.find(d => d.id === documentType)?.name
      })

      setAuthTokenToCookie(token)
      router.reload()
    } catch (error: any) {
      const errorMessage = error.message as string

      if (errorMessage === 'Usuario y Password Incorrectos') {
        setError('password', {
          message: 'Las credenciales de acceso son incorrectas',
          type: 'validate'
        })
        setIsLoadingLogin(false)

        return
      }

      if (errorMessage === 'No existe usuario con el documento ingresado') {
        openAlert('Te invitamos a hacer clic en "Regístrate" para crear tu cuenta', {
          title: 'Usuario no registrado',
          type: 'info'
        })
        reset(initialLoginForm)
        setIsLoadingLogin(false)

        return
      }

      openAlert(SERVER_ERROR_MESSAGE)
      setIsLoadingLogin(false)
    }
  }

  return (
    <Stack mt={isMobile ? '2.4rem' : undefined} width='100%'>
      <LoginTopInfo
        href='/autenticacion/registro'
        textButton='Regístrate'
        textLabel='¿No tienes cuenta?'
      />

      <Stack
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        maxWidth={isMobile ? undefined : '39.2rem'}
      >
        <Typography
          mb={isMobile ? '2.4rem' : '2.2rem'}
          color='secondary'
          variant={isMobile ? 'd3' : 'h1_bold'}
        >
          Iniciar sesión
        </Typography>
        <Stack direction='row' mb='1.6rem' width='100%' gap={isMobile ? '1.6rem' : '2.4rem'}>
          <RHFCustomSelect
            control={control}
            options={documentTypes ?? []}
            label='Tipo de documento'
            name='documentType'
            fullWidth
          />
          <CustomInput
            label='Nº documento'
            placeholder='Nº documento'
            value={values.documentNumber}
            onChange={onChangeDocumentNumber}
            name='documentNumber'
            error={Boolean(errors?.documentNumber)}
            helperText={errors?.documentNumber?.message}
            inputMode='numeric'
            fullWidth
          />
        </Stack>
        <RHFCustomInput
          sxContainer={{ mb: isMobile ? '2.4rem' : '3.2rem' }}
          control={control}
          name='password'
          type='password'
          isPassword
          placeholder='Ingresa tu contraseña'
          label='Contraseña'
        />
        <Stack
          mb='4rem'
          direction='row'
          width='100%'
          justifyContent='flex-start'
          alignItems='center'
        >
          {/* <RHFCustomCheckbox
            spacing='0.8rem'
            control={control}
            name='remember_session'
            label='Recordar sesión'
            variant='body3'
            sxLabel={{ color: colors.text_body_b2 }}
          /> */}
          <Link
            variant='link_medium'
            sx={{ color: colors.primary_darkest, textDecorationLine: 'underline' }}
            href='/autenticacion/iniciar-sesion/restablecer'
            component={NextLink}
          >
            Olvidé mi contraseña
          </Link>
        </Stack>
        <Button
          sx={{ mb: isMobile ? '2.4rem' : '3.2rem' }}
          type='submit'
          size='large'
          fullWidth
          disabled={isSubmitting}
        >
          Iniciar sesión
        </Button>
      </Stack>

      <BackdropLoader loading={isLoadingLogin} title='Verificando datos...' />
    </Stack>
  )
}
